<template>
  <WsMain>
    <WsReadSection
      :id="$route.params.id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="_leftFields"
      :rightFields="rightFields"
      v-model="modelData"
    ></WsReadSection>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/service_plan_item";
export default {
  data() {
    return {
      ...model,
      modelData: null,
      rightFields: [],
    };
  },
  computed: {
    _leftFields() {
      const fields = ["name", "type"];
      if (this.modelData && this.modelData.type) {
        const type = this.modelData.type;
        if (type == "custom-items") {
          fields.push("items");
        }
        if (type == "count") {
          fields.push("unit");
        }
      }
      return fields;
    },
  },
};
</script>